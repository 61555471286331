import React, { useState } from "react";
import IntlTelInput, { IntlTelInputProps } from "react-intl-tel-input-18";
import "react-intl-tel-input-18/dist/main.css";
import { ErrorMessage } from "./ErrorMessage";
// import { countryDataList } from "tools/countryData";

interface Props extends IntlTelInputProps {
  label?: string;
  onChange: (phoneNumber: string, phoneCode: string, isValid: boolean) => void;
  dialCode?: string;
  errorMessage?: string;
  containerClassName?: string;
  isRequiredField?: boolean;
  value?: string;
  disabled?: boolean;
}

export const IntlTelInputWrapper: React.FC<Props> = (props) => {
  // const defaultCountry = props.dialCode
  //   ? countryDataList
  //       .find((country) => country.dial_code === props.dialCode)
  //       ?.code.toLowerCase()
  //   : "in";
  // console.log(defaultCountry);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  return (
    <div className={props.containerClassName}>
      <label
        htmlFor={props.fieldId}
        className={`${props.isRequiredField ? "required" : ""} small`}
      >
        {props.label}
      </label>
      <IntlTelInput
        fieldId={props.fieldId}
        preferredCountries={["in"]}
        // value={props.value}
        containerClassName={`intl-tel-input w-100`}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        value={props.value}
        onSelectFlag={(currentNumber, countryData, fullNumber, isValid) => {
          const dialCode = countryData.dialCode;
          props.onChange(currentNumber, `+${dialCode}`, isValid);
          if (!isValid) {
            setErrorMessage("Please enter a valid number.");
          } else {
            setErrorMessage(null);
          }
        }}
        onPhoneNumberChange={(isValid, value, selectedCountry) => {
          const dialCode = `+${selectedCountry.dialCode}`;
          let phoneNumber = value;
          if (value.includes(dialCode)) {
            phoneNumber = value.replace(dialCode, "");
          }
          props.onChange(phoneNumber.trim(), dialCode, isValid);
          if (!isValid) {
            setErrorMessage("Please enter a valid number.");
          } else {
            setErrorMessage(null);
          }
        }}
        formatOnInit={false}
        inputClassName="form-control m-0 w-100"
        style={{ width: "100%" }}
      />
      <ErrorMessage errorMessage={props.errorMessage || errorMessage} />
    </div>
  );
};
