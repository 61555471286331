import React from "react";
import styles from "./LoadingSpinnerWithOverlay.module.scss";
export const LoadingSpinnerWithOverlay: React.FC<{
  loading: boolean;
}> = (props) => {
  return props.loading ? (
    <>
      <div className={styles.backgroundOverlay}></div>
      <div className={styles.spinnerContainer}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </>
  ) : null;
};
