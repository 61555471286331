import { FormEvent, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import loginpageStyle from "./../loginSignup/loginpageStyle.module.scss";
import { activityAxios } from "../../axios/activityAxios";
import { IJSONResponse } from "../../activitiescommonfnb/types/common.interface";
import { setAuthData } from "../../slice/auth.slice";
import { useAppDispatch } from "../../store";
import {
  ISignUp,
  signUpTemplate,
} from "../../activitiescommonfnb/types/signUp.interface";
import {
  agencySchema,
  signUpSchema,
  TAgencySchema,
  TSignUp,
} from "../../activitiescommonfnb/schemas/signUp.schema";
import { ZodHelperService } from "../../activitiescommonfnb/schemas/zodHelper.service";
import { Path } from "react-hook-form";
import { ReCaptchaWrapper } from "../../components/common/ReCaptchaWrapper";
import signupPageImage from "../../assets/images/loginSignup.jpg";
import { useRecaptcha } from "../../hooks/useGoogleRechaptcha";
import { Spinner } from "react-bootstrap";
import AgencyDetailsForm from "./AgencyDetailsForm";
import VerifyEmailForm from "./VerifyEmailForm";
import UserForm from "./UserForm";
import { EnumUserVerificationType } from "../../helper/adminHelper";

const SignUp = () => {
  return (
    <div className={loginpageStyle.loginPage}>
      <div className="container">
        <div className="d-flex justify-content-center">
          <div className="image">
            <img
              className={`img-fluid ${loginpageStyle.loginSideImage}`}
              src={signupPageImage}
              alt="signup image"
            />
          </div>
          <ReCaptchaWrapper>
            <SignupForm />
          </ReCaptchaWrapper>
        </div>
      </div>
    </div>
  );
};

const SignupForm = () => {
  const dispatch = useAppDispatch();
  const [signUpDetails, setSignUpDetails] = useState<ISignUp>(signUpTemplate);
  const [searchParams] = useSearchParams();
  const [sendOtpLoading, setSendOtpLoading] = useState(false);
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState<string | null>(null);
  const [loadingButton, setLoadingButton] = useState(false);
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const emptySignUpError: { [key in Path<TSignUp>]: string } | null = null;
  const [signUpError, setSignUpError] = useState<
    { [key in Path<TSignUp>]: string } | null
  >(emptySignUpError);
  const emptyAgencyDetailError:
    | { [key in Path<TAgencySchema>]: string }
    | null = null;
  const [agencyDetailError, setAgencyDetailError] = useState<
    { [key in Path<TAgencySchema>]: string } | null
  >(emptyAgencyDetailError);

  const [emailError, setEmailError] = useState(false);
  const [isUserVerified, setIsUserVerified] = useState(false);
  const { getRecaptchaToken } = useRecaptcha();
  const [signUpPage, setSignUpPage] = useState({
    page1: true,
    page2: false,
    page3: false,
  });
  const [token, setToken] = useState(null);
  const [isEmailVerification, setIsEmailVerification] = useState(false);

  const [timeLeft, setTimeLeft] = useState<number>(0);
  const navigate = useNavigate();

  const currentStep = searchParams.get("step") || "1";
  const type = searchParams.get("type");
  useEffect(() => {
    if (currentStep !== "1" && currentStep !== "2" && currentStep !== "3") {
      navigate("/signUp?step=1");
    }
  }, [currentStep, navigate]);
  // Handle routing for steps dynamically
  const handleStepChange = (nextStep: number) => {
    navigate(`/signUp?step=${nextStep}`);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (timeLeft) {
        setTimeLeft((old) => {
          if (old) {
            old -= 1;
          }
          return old;
        });
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [timeLeft]);

  useEffect(() => {
    // Check if phone data exists in localStorage
    const storedPhoneData = localStorage.getItem("phoneData");
    const storedEmailData = localStorage.getItem("emailData");

    if (storedPhoneData) {
      const parsedPhoneData = JSON.parse(storedPhoneData);
      setSignUpDetails((prevDetails) => ({
        ...prevDetails,
        phoneCode: parsedPhoneData.phoneCode,
        phoneNumber: parsedPhoneData.phoneNumber,
        firstName: parsedPhoneData.firstName,
        lastName: parsedPhoneData.lastName,
        isPhoneValid: parsedPhoneData.isPhoneValid,
      }));
      setToken(parsedPhoneData.token);
      setReferenceNumber(parsedPhoneData.referenceNumber);
      setTimeLeft(90);
      // setShowOTPInput(true); // Automatically show OTP input
    }

    if (storedEmailData && currentStep === "2") {
      const parsedEmailData = JSON.parse(storedEmailData);
      if (!storedPhoneData) {
        // only for typescript error
        throw new Error("error");
      }
      const parsedPhoneData = JSON.parse(storedPhoneData);

      setSignUpDetails((prevDetails) => ({
        ...prevDetails,
        ...parsedPhoneData,
        email: parsedEmailData.email,
      }));

      setReferenceNumber(parsedEmailData.referenceNumber);
      setToken(parsedEmailData.token);
      setTimeLeft(90);
    }

    if (storedEmailData && currentStep === "3") {
      const parsedEmailData = JSON.parse(storedEmailData);

      setToken(parsedEmailData.token);
    }
  }, []);

  useEffect(() => {
    // const type = searchParams.get("type");

    // Show OTP input if the URL has `step=1&type=phone`
    if (currentStep === "1" && type === "phone") {
      setShowOTPInput(true);
    }

    // Handling when type=email and step=2
    if (currentStep === "2" && type === "email") {
      setShowOTPInput(true); // Show OTP input for email verification
      setIsEmailVerification(true); // Track email verification state
      setSignUpPage({ page1: false, page2: true, page3: false }); // Go to step 2 (Email verification)
    }
  }, [searchParams]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  useEffect(() => {
    if (searchParams.get("isPhNoValid") === "true") {
      setShowOTPInput(true);
    }
    if (type === "email") {
      setIsUserVerified(true);
    }
  }, [searchParams]);

  const registerUser = async (e: FormEvent<HTMLElement>) => {
    setLoadingButton(true);
    e.preventDefault();
    const validatedData = signUpSchema.safeParse(signUpDetails);

    if (!validatedData.success) {
      const formattedError: any = ZodHelperService.formatZodErrors(
        validatedData.error,
        "Error"
      );

      setSignUpError(formattedError);
      setLoadingButton(false);
      toast.error("Invalid Data");
      return;
    }

    if (!signUpDetails?.isPhoneValid) {
      setLoadingButton(false);
      toast.error("Please enter a valid phone number");
      return;
    }

    const recaptchaToken = await getRecaptchaToken();

    if (!recaptchaToken) {
      throw new Error("recaptcha token is required");
    }

    const apiRes = await activityAxios.post<IJSONResponse<any>>(
      "/auth/sendPhoneVerificationOtp",
      {
        userData: {
          firstName: validatedData.data.firstName,
          lastName: validatedData.data.lastName,
          value: `${validatedData.data.phoneCode}_${validatedData.data.phoneNumber}`,
          type: EnumUserVerificationType.PHONE,
        },
      },
      {
        headers: {
          "captcha-token": recaptchaToken,
        },
      }
    );
    if (apiRes?.data.success) {
      toast.success("OTP Sent");
      const phoneData = {
        firstName: validatedData.data.firstName,
        lastName: validatedData.data.lastName,
        phoneCode: validatedData.data.phoneCode,
        phoneNumber: validatedData.data.phoneNumber,
        isPhoneValid: validatedData.data.isPhoneValid,
        referenceNumber: apiRes.data.result?.referenceNumber,
        token: apiRes.data.result.token,
      };
      localStorage.setItem("phoneData", JSON.stringify(phoneData));
      // setSignUpDetails((old) => ({
      //   ...old,
      //   userId: apiRes.data.result.userId,
      // }));
      setToken(apiRes.data.result.token);
      setShowOTPInput(true);
      setReferenceNumber(apiRes.data.result?.referenceNumber);
      setTimeLeft(90);
      setSignUpError(null);
      navigate("/signUp?step=1&type=phone");
      // handleNextStep(2); // Move to next step
    } else {
      toast.error(apiRes?.data.errorMessage || "Something Went Wrong");
    }
    setSendOtpLoading(false);
    setLoadingButton(false);
  };

  const verifyEmail = async (e: FormEvent<HTMLElement>) => {
    e.preventDefault();
    setLoadingButton(true);
    if (!signUpDetails.email) {
      setEmailError(true);
      return toast.error("Email not Found");
    }

    const recaptchaToken = await getRecaptchaToken();
    if (!recaptchaToken) {
      throw new Error("recaptcha token is required");
    }

    const apiRes = await activityAxios.post<IJSONResponse<any>>(
      "/auth/sendEmailVerificationOtp",
      {
        userData: {
          type: EnumUserVerificationType.EMAIL,
          value: signUpDetails.email,
          token,
        },
      },

      {
        headers: {
          "captcha-token": recaptchaToken,
        },
      }
    );
    if (apiRes?.data.success) {
      toast.success("OTP Sent");
      const emailData = {
        email: signUpDetails.email,
        referenceNumber: apiRes.data.result?.referenceNumber,
        token: apiRes?.data.result.token,
      };
      localStorage.setItem("emailData", JSON.stringify(emailData));
      setShowOTPInput(true);
      setToken(apiRes?.data.result.token);
      setReferenceNumber(apiRes.data.result?.referenceNumber);
      setTimeLeft(90);
      setIsEmailVerification(true);
      navigate("/signUp?step=2&type=email");
    } else {
      toast.error(apiRes?.data.errorMessage || "Something Went Wrong");
    }
    setSendOtpLoading(false);
    setLoadingButton(false);
  };

  const saveAgencyDetail = async (e: FormEvent<HTMLElement>) => {
    setLoadingButton(true);
    try {
      e.preventDefault();
      const validatedData = agencySchema.safeParse(signUpDetails);
      if (!validatedData.success) {
        const formattedError: any = ZodHelperService.formatZodErrors(
          validatedData.error,
          "Error"
        );
        setAgencyDetailError(formattedError);
        throw new Error("Invalid Data");
      }

      const recaptchaToken = await getRecaptchaToken();
      if (!recaptchaToken) {
        throw new Error("recaptcha token is required");
      }

      const apiRes = await activityAxios.post<IJSONResponse<any>>(
        "/auth/saveAgencyOnSignUp",
        { userData: validatedData.data, token },
        {
          headers: {
            "captcha-token": recaptchaToken,
          },
        }
      );
      setAgencyDetailError(null);
      if (apiRes?.data.success) {
        setTimeLeft(90);
        dispatch(setAuthData(apiRes?.data?.result));
        setLoadingButton(false);
        // Clear phoneData and emailData from localStorage after successful signup
        localStorage.removeItem("phoneData");
        localStorage.removeItem("emailData");

        window.location.href = "/";
        return;
      } else {
        throw new Error(apiRes?.data.errorMessage);
      }
    } catch (error: any) {
      toast.error(error.message || "Something went wrong");
    }
    setLoadingButton(false);
  };

  const verifySignupOtp = async (e: any) => {
    setLoadingButton(true);
    try {
      e.preventDefault();
      const recaptchaToken = await getRecaptchaToken();
      if (!recaptchaToken) {
        throw new Error("recaptcha token is required");
      }
      if (signUpDetails) {
        const apiRes = await activityAxios.post(
          "auth/validateUserPhoneVerificationOtp",
          {
            token,
            otp,
          },
          {
            headers: {
              "captcha-token": recaptchaToken,
            },
          }
        );
        if (apiRes?.data.success) {
          setIsUserVerified(true);
          setShowOTPInput(false);
          setSignUpPage({
            page1: false,
            page2: true,
            page3: false,
          });
          const phoneData = JSON.parse(
            localStorage.getItem("phoneData") || "{}"
          );
          phoneData.token = apiRes?.data.result.token; // Update the token
          localStorage.setItem("phoneData", JSON.stringify(phoneData)); // Save it back to localStorage
          setToken(apiRes?.data.result.token);
          handleStepChange(2);
        } else {
          toast.error(apiRes?.data.errorMessage || "Something went wrong");
        }
      }
    } catch (error: any) {
      setErrorMessage(error.message || "Something went wrong");
      toast.error(error.message || "Something went wrong");
    }
    setLoadingButton(false);
  };

  const verifyEmailOtp = async (e: any) => {
    setLoadingButton(true);
    try {
      e.preventDefault();
      const recaptchaToken = await getRecaptchaToken();
      if (!recaptchaToken) {
        throw new Error("recaptcha token is required");
      }
      if (signUpDetails) {
        const apiRes = await activityAxios.post(
          "auth/validateUserEmailVerificationOtp",
          {
            token,
            otp,
          },
          {
            headers: {
              "captcha-token": recaptchaToken,
            },
          }
        );
        if (apiRes?.data.success) {
          setShowOTPInput(false);
          setSignUpPage({
            page1: false,
            page2: false,
            page3: true,
          });
          setToken(apiRes?.data.result.token);
          // Update the token and reference number in localStorage
          const emailData = JSON.parse(
            localStorage.getItem("emailData") || "{}"
          );
          emailData.token = apiRes?.data.result.token; // Update the token
          localStorage.setItem("emailData", JSON.stringify(emailData)); // Save updated data back to localStorage
          setLoadingButton(false);
          handleStepChange(3); // Move to next step
        } else {
          toast.error(apiRes?.data.errorMessage || "Something went wrong");
        }
      }
    } catch (error: any) {
      setErrorMessage(error.message || "Something went wrong");
      toast.error(error.message || "Something went wrong");
    }
    setLoadingButton(false);
  };

  const sendOtp = async (e: FormEvent<HTMLElement>) => {
    setLoadingButton(false);

    try {
      setSendOtpLoading(true);
      e.preventDefault();
      if (!signUpDetails?.isPhoneValid) {
        throw new Error("Please enter a valid phone number.");
      }

      const recaptchaToken = await getRecaptchaToken();
      if (!recaptchaToken) {
        throw new Error("recaptcha token is required");
      }

      let apiRes;
      if (isEmailVerification) {
        apiRes = await activityAxios.post<IJSONResponse<any>>(
          "/auth/resendEmailSignUpOtp",
          {
            token,
          },
          {
            headers: {
              "captcha-token": recaptchaToken,
            },
          }
        );
      } else {
        apiRes = await activityAxios.post<IJSONResponse<any>>(
          "/auth/resendPhoneSignUpOtp",
          {
            token,
          },
          {
            headers: {
              "captcha-token": recaptchaToken,
            },
          }
        );
      }

      if (apiRes?.data.success) {
        toast.success("OTP Sent Successfully");
        setTimeLeft(90);
        if (isEmailVerification) {
          const emailData = JSON.parse(
            localStorage.getItem("emailData") || "{}"
          );
          emailData.referenceNumber = apiRes.data.result.referenceNumber;
          localStorage.setItem("emailData", JSON.stringify(emailData));
          setReferenceNumber(emailData.referenceNumber); // Update state for UI
        } else {
          const phoneData = JSON.parse(
            localStorage.getItem("phoneData") || "{}"
          );
          phoneData.referenceNumber = apiRes.data.result.referenceNumber;
          localStorage.setItem("phoneData", JSON.stringify(phoneData));
          setReferenceNumber(phoneData.referenceNumber); // Update state for UI
        }
      } else {
        throw new Error(apiRes?.data.errorMessage);
      }
    } catch (error: any) {
      console.log(error);

      toast.error(error.message || "Something went wrong");
    }
    setLoadingButton(false);
    setSendOtpLoading(false);
  };

  return (
    <form className={loginpageStyle.loginFormContainer}>
      <div className="d-flex">
        {showOTPInput && (
          <div
            role="button"
            className="me-2"
            onClick={() => {
              setErrorMessage("");
              setShowOTPInput(false);
              setReferenceNumber(null);
            }}
          >
            <i className="fa-solid fa-arrow-left"></i>
          </div>
        )}
        <b>{showOTPInput ? "Go Back" : "Sign Up"}</b>
      </div>

      {showOTPInput ? (
        <div className="mt-4">
          <label htmlFor="" className="small">
            Enter OTP
          </label>
          <input
            type="text"
            className="form-control"
            placeholder=""
            onChange={(e) => {
              setOtp(e.target.value);
            }}
          />
          <p className="small text-danger p-0 m-0">{errorMessage}</p>
          <br />
          <div className="text-end">
            <button
              type="submit"
              className="btn-sm btn btn-primary w-100 mt-4"
              onClick={(e) =>
                isUserVerified ? verifyEmailOtp(e) : verifySignupOtp(e)
              }
              disabled={loadingButton}
            >
              {loadingButton ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Verify"
              )}
            </button>

            {referenceNumber && showOTPInput && (
              <div
                className="alert alert-warning small py-1 text-start mt-2"
                role="alert"
              >
                OTP sent to your mobile with ref. number:{" "}
                <strong>{referenceNumber}</strong>.
              </div>
            )}

            <p role="button" className={`text-center w-100 small mt-3  }`}>
              {timeLeft > 0 ? (
                <span>
                  Resend OTP {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                </span>
              ) : (
                <div className="d-flex align-items-center justify-content-center">
                  <span
                    role="button"
                    className="link-primary me-2"
                    onClick={sendOtp}
                  >
                    Resend OTP
                  </span>
                  {sendOtpLoading && (
                    <>
                      <div
                        className="spinner-grow spinner-grow-sm me-1 "
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow spinner-grow-sm me-1"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow spinner-grow-sm "
                        role="status"
                      ></div>
                    </>
                  )}
                </div>
              )}
            </p>
          </div>
        </div>
      ) : (
        <>
          {currentStep === "1" && (
            <UserForm
              loadingButton={loadingButton}
              setSignUpDetails={setSignUpDetails}
              signUpDetails={signUpDetails}
              registerUser={registerUser}
              signUpError={signUpError}
            />
          )}
          {currentStep === "2" && (
            <VerifyEmailForm
              emailError={emailError}
              loadingButton={loadingButton}
              setSignUpDetails={setSignUpDetails}
              signUpDetails={signUpDetails}
              verifyEmail={verifyEmail}
              handleGoBack={() => {
                navigate("/signUp?step=1");
                // setSignUpPage({
                //   page1: true,
                //   page2: false,
                //   page3: false,
                // });
                setIsUserVerified(false);
              }}
            />
          )}
          {currentStep === "3" && (
            <AgencyDetailsForm
              agencyDetailError={agencyDetailError}
              loadingButton={loadingButton}
              saveAgencyDetail={saveAgencyDetail}
              setSignUpDetails={setSignUpDetails}
              signUpDetails={signUpDetails}
              handleGoBack={
                () => navigate("/signUp?step=2")
                // setSignUpPage({
                //   page1: false,
                //   page2: true,
                //   page3: false,
                // })
              }
            />
          )}
        </>
      )}
    </form>
  );
};

export default SignUp;
