import React from "react";
import { Link } from "react-router-dom";
import iata from "../../assets/images/certificate/logo/IATAlogo.png";
import taai from "../../assets/images/certificate/logo/taailogo.png";
import adtoi from "../../assets/images/certificate/logo/ADTOILogo.png";
import iatte from "../../assets/images/certificate/logo/iattelogo.png";
import hdfc from "../../assets/images/aboutUs/hdfc.png";
import icici from "../../assets/images/aboutUs/icicc.png";
import sbi from "../../assets/images/aboutUs/sbi.png";
import qrPaymentScanner from "../../assets/images/aboutUs/qrScanner.jpeg";
import quotationFooterStyle from "./QuotationFooterStyle.module.scss";
import { CallLogsModal } from "../../screens/CallLogs/CallLogsModal";
import { useAppSelector } from "../../store";
import { EnumUserType } from "../../activitiescommonfnb/types/User.interface";

const QuotationFooter: React.FC<{}> = () => {
  const { data: userData } = useAppSelector((state) => state.authData);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    window.open("/aboutUs", "_blank");
  };
  return (
    <>
      {userData?.userType !== EnumUserType.TRAVEL_AGENT ? (
        <div className={`bg-light p-4 ${quotationFooterStyle.quotationFooter}`}>
          <div className={quotationFooterStyle.aboutUs}>
            <div className="d-flex align-items-center justify-content-between mb-4 small">
              <h5 className="text-center m-0">Company Details </h5>
              <a
                href="/aboutUs"
                onClick={handleClick}
                className="btn bg-secondary text-white btn-sm rounded-pill fw-bold px-4"
              >
                <div className="rounded-pill">
                  Company Profile
                  <i className="fa-solid fa-link ms-1"></i>
                </div>
              </a>
            </div>
            <h6>About Us </h6>
            <div className={quotationFooterStyle.aboutpara}>
              At PackageKart.com, we harness over 20 years of expertise to
              deliver comprehensive tour packages, including essential
              components like attraction tickets and seamless transfer services,
              exclusively for B2B travel agents. With a focus on select
              destinations, we provide a meticulously crafted selection of
              services, allowing you to customize every aspect of your clients'
              travel experiences. Our commitment to transparency and reliability
              ensures that you receive the highest level of service, supported
              by a well-trained team available 24x7. Trust PackageKart.com to be
              your dependable partner, offering professionalism and excellence
              at every step of the journey.
            </div>
          </div>
          {/* <div className={quotationFooterStyle.certificate}>
        <img
          className={quotationFooterStyle.certificateIcon}
          src={iata}
          alt=""
        />
        <img
          className={quotationFooterStyle.certificateIcon}
          src={adtoi}
          alt=""
        />
        <img
          className={quotationFooterStyle.certificateIcon}
          src={iatte}
          alt=""
        />
        <img
          className={quotationFooterStyle.certificateIcon}
          src={taai}
          alt=""
        />
      </div> */}
          <div className={quotationFooterStyle.dealsIn}>
            <h6 className="m-0">Deals In : </h6>
            {/* <div className={quotationFooterStyle.moduleItem}>Flights</div> */}
            <div className={quotationFooterStyle.moduleItem}>Visa</div>
            <div className={quotationFooterStyle.moduleItem}>Tour Package</div>
            <div className={quotationFooterStyle.moduleItem}>Hotels</div>
            <div className={quotationFooterStyle.moduleItem}>
              Travel Insurance
            </div>
          </div>
          <div className={quotationFooterStyle.bankDetails}>
            {/* <h6 className="mt-2"> QR Code Scanner</h6> */}
            <div className="row align-items-center">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="">
                  <h6>Bank Account Details :</h6>
                  <span className="text-secondary fw-bold">
                    Please Make All Transaction in the Following Details.
                  </span>
                  <div>
                    Cash you can deposit in hdfc only below 50k INR. Cash
                    deposit not allowed in any other account. Please don't
                    transfer any payment from any account outside india. Bank
                    holds payment sent in business account with wrong purpose
                    code e.g family maintenance.
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-2 col-md-3 col-sm-3 col-3">
            <div className="bg-light text-center">
              <img
                className={`img-fluid ${quotationFooterStyle.qrScanner}`}
                src={qrPaymentScanner}
                alt=""
              />
              <span className="fw-bold">QR / UPI Scanner</span>
            </div>
          </div> */}
            </div>
            <div className={quotationFooterStyle.bankDetailsItem}>
              <div className="row align-items-center">
                <div className="col-lg-2 col-md-2 col-sm-2 col-2">
                  <img
                    src={hdfc}
                    className={`img-fluid ${quotationFooterStyle.bankLogo}`}
                    alt=""
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                  <p className="small">Account Holder :</p>
                  SIPPI SOFTWARE SOLUTIONS PVT. LTD.
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                  <p className="small">Account No. :</p>
                  99998199977740
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                  <p className="small">IFSC CODE :</p>
                  HDFC0000871
                </div>
              </div>
            </div>
          </div>
          <div className={quotationFooterStyle.contactSupport}>
            <h6 className="mb-3">For Feedback / Complaints Contact :</h6>{" "}
            <span className="alert bg-secondary fw-bold text-white py-2">
              Phone No : +91 99961 55563 ,
            </span>
            <span className="alert bg-secondary fw-bold text-white py-2 ms-3">
              Email : support@packagekart.com
            </span>
          </div>
          <div className="mt-3 small">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                {" "}
                Sippi Software Solutions Private Limited ©{" "}
                {new Date().getFullYear()}
              </div>
              <div>
                <Link to={"/aboutUs"} target="_blank">
                  To Know More{" "}
                  <i className="fa-solid fa-arrow-up-right-from-square ms-1"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          dangerouslySetInnerHTML={{ __html: userData.agency.quotationFooter }}
        ></div>
      )}
    </>
  );
};

export default QuotationFooter;
