import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAppSelector } from "../../store";
import { activityAxios } from "../../axios/activityAxios";
import { toast } from "react-toastify";
import { IJSONResponse } from "../../activitiescommonfnb/types/common.interface";
import { zodResolver } from "@hookform/resolvers/zod";
import OtpModal from "./OtpModal";
import { AppCkEditor } from "../../commonUi/AppCkEditor";
import {
  ProfileDataSchema,
  TProfileData,
} from "../../activitiescommonfnb/schemas/myProfile.schema";
import { lowerCase } from "lodash";
import { restrictToNumbers } from "../../helper/adminHelper";
import {
  EnumUserType,
  ICountryData,
} from "../../activitiescommonfnb/types/User.interface";
import { CountrySelect } from "../../components/common/Selects/CountrySelect";
import { useRecaptcha } from "../../hooks/useGoogleRechaptcha";
import { IntlTelInputWrapper } from "../../commonUi/IntlTelInputWrapper";
import { LoadingButton } from "../../commonUi/LoadingButton";
import { Spinner } from "react-bootstrap";
import { LoadingSpinnerWithOverlay } from "../../commonUi/LoadingSpinnerWithOverlay";

const MyProfile = () => {
  const userData = useAppSelector((state) => state.authData.data);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isPhoneNumberVerified, setIsPhoneNumberVerified] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [countryData, setCountryData] = useState<ICountryData | null>(null);
  const [districts, setDistricts] = useState<string[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [isEmailVerification, setIsEmailVerification] = useState(false);
  const [pincodeDataLoader, setPincodeDataLoader] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    clearErrors,
    watch,
  } = useForm<TProfileData>({
    resolver: zodResolver(ProfileDataSchema),
  });

  const { getRecaptchaToken } = useRecaptcha();

  const getProfileData = async () => {
    const apiRes = await activityAxios.post<IJSONResponse>(
      "/user/getUserWithAgency"
    );

    if (apiRes.data.success && apiRes.data.result) {
      Object.keys(apiRes.data.result).forEach((key) => {
        setValue(key as keyof TProfileData, apiRes.data.result[key]);
      });
      setDistricts([apiRes.data.result?.agency?.district]);
      setCountryData(apiRes.data.result?.agency?.country);
      setIsPhoneNumberVerified(apiRes.data.result.isPhoneVerified);
      setIsEmailVerified(apiRes.data.result.isEmailVerified);
      if (!apiRes.data.result?.agency?.agencyName) {
        toast.error("Please update agency data");
      }
    } else {
      toast.error(apiRes.data.errorMessage || "Unable to fetch profile data");
    }
  };

  const onSubmit = async (data: TProfileData) => {
    setLoading(true);
    if (!isEmailVerified) {
      toast.error("Please verify your email first");
      setLoading(false);
      return;
    }

    if (!isPhoneNumberVerified) {
      toast.error("Please verify your phone number first");
      setLoading(false);
      return;
    }

    if (userData?.userType === EnumUserType.STAFF) {
      data.agency = null;
    }

    const apiRes = await activityAxios.post<IJSONResponse>(
      "/user/updateProfileData",
      {
        data,
      }
    );
    if (apiRes.data.success) {
      reset(apiRes.data.result);
      setDistricts([apiRes.data.result.agency.district]);
      setCountryData(apiRes.data.result.agency.country);
      setIsPhoneNumberVerified(apiRes.data.result.isPhoneVerified);
      setIsEmailVerified(apiRes.data.result.isEmailVerified);

      toast.success("Profile updated successfully");
    } else {
      toast.error(apiRes.data.errorMessage || "Unable to save profile data");
    }
    setLoading(false);
  };

  const getCityAndState = async (pincode: number) => {
    setPincodeDataLoader(true);
    const apiRes = await activityAxios.post<IJSONResponse<any>>(
      "/search/getPincodeData",
      { pincode }
    );
    if (apiRes?.data.success) {
      setValue("agency.state", apiRes?.data.result?.state);
      setDistricts(apiRes?.data.result?.district);
    } else {
      toast.error(apiRes?.data.errorMessage || "Something Went Wrong");
    }
    setPincodeDataLoader(false);
  };

  const handleVerifyEmailClick = async () => {
    setIsEmailVerification(true);
    setLoading(true);
    if (watch("email")) {
      const recaptchaToken = await getRecaptchaToken();
      const apiRes = await activityAxios.post<IJSONResponse<any>>(
        "/auth/sendEmailVerificationOtp",
        { email: watch("email") },

        {
          headers: {
            "captcha-token": recaptchaToken,
          },
        }
      );
      if (apiRes?.data.success) {
        setLoading(false);
        toast.success("OTP Sent");
        setOtpModal(true);
      } else {
        toast.error(apiRes?.data.errorMessage || "Something Went Wrong");
      }
    } else {
      toast.error("Email is required");
    }
    setLoading(false);
  };

  const handleVerifyPhoneNumberClick = async () => {
    setIsEmailVerification(false);
    setLoading(true);

    if (watch("phoneNumber")) {
      const recaptchaToken = await getRecaptchaToken();
      const apiRes = await activityAxios.post<IJSONResponse<any>>(
        "/auth/sendVerificationOtp",
        { phoneNumber: watch("phoneNumber"), phoneCode: watch("phoneCode") },
        {
          headers: {
            "captcha-token": recaptchaToken,
          },
        }
      );
      if (apiRes?.data.success) {
        toast.success("OTP Sent");
        setOtpModal(!otpModal);
        setLoading(false);
      } else {
        toast.error(apiRes?.data.errorMessage || "Something Went Wrong");
      }
    } else {
      toast.error("Phone Number is required");
    }
    setLoading(false);
  };

  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <>
      <div className="container mt-4">
        <h3 className="fw-bold text-primary">My Profile</h3>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-5">
          <div className="row">
            <div className="mb-3 col-6 ">
              <label className="required">First Name</label>
              <input className="form-control" {...register("firstName")} />
              {errors.firstName && (
                <p className="text-danger small">{errors.firstName.message}</p>
              )}
            </div>

            <div className="mb-3 col-6">
              <label className="required">Last Name</label>
              <input className="form-control" {...register("lastName")} />
              {errors.lastName && (
                <p className="text-danger small">{errors.lastName.message}</p>
              )}
            </div>
          </div>

          <div className="row">
            <div className="mb-3 col-6">
              <div className="d-flex align-items-center justify-content-between p-0">
                <label className="required">Email</label>

                {/* {!isEmailVerified && (
                  <button
                    type="button"
                    className="btn text-primary p-0"
                    onClick={handleVerifyEmailClick}
                  >
                    verify{" "}
                    {loading ? <Spinner animation="border" size="sm" /> : ""}
                  </button>
                )} */}
              </div>
              <input
                className="form-control"
                {...register("email")}
                onChange={(e) => {
                  if (e.target.value !== userData?.email) {
                    setIsEmailVerified(false);
                  }
                  setValue("email", e.target.value);
                }}
                disabled={true}
              />
              {errors.email && (
                <p className="text-danger small">{errors.email.message}</p>
              )}
            </div>

            <div className="mb-3 col-6">
              <div className="d-flex align-items-center justify-content-between p-0">
                <label className="required">Phone Number</label>
                {/* {!isPhoneNumberVerified && (
                  <button
                    type="button"
                    className="btn text-primary p-0"
                    onClick={handleVerifyPhoneNumberClick}
                  >
                    verify{" "}
                    {loading ? <Spinner animation="border" size="sm" /> : ""}
                  </button>
                )} */}
              </div>
              <IntlTelInputWrapper
                value={watch("phoneNumber")}
                onChange={(phoneNumber, phoneCode, isValid) => {
                  if (phoneNumber !== userData?.phoneNumber) {
                    setIsPhoneNumberVerified(false);
                  }
                  setValue("phoneNumber", phoneNumber);
                  setValue("phoneCode", phoneCode);
                }}
                disabled={true}
              />
              {errors.phoneNumber && (
                <p className="text-danger small">
                  {errors.phoneNumber.message}
                </p>
              )}
            </div>
          </div>

          {userData?.userType !== EnumUserType.STAFF && (
            <>
              <div className="mb-3">
                <label className="required">Agency Name</label>
                <input
                  className="form-control"
                  {...register("agency.agencyName")}
                />
                {errors.agency?.agencyName && (
                  <p className="text-danger small">
                    {errors.agency?.agencyName?.message}
                  </p>
                )}
              </div>

              <div className="row ">
                <div className="mb-3 col-6">
                  <label className="required">Country</label>
                  <CountrySelect
                    value={countryData}
                    onChange={(country) => {
                      setCountryData(country);
                      setValue("agency.countryCode", country?.code || "");

                      if (country?.code !== "IN") {
                        setValue("agency.district", "");
                        setValue("agency.city", "");
                        setValue("agency.state", "");
                        setValue("agency.pincode", "");
                      }
                    }}
                  />
                </div>

                <div className="mb-3 col-6">
                  <label
                    className={
                      lowerCase(watch("agency.countryCode")) === "in"
                        ? "required"
                        : ""
                    }
                  >
                    Pincode
                  </label>
                  <div className="position-relative">
                    <input
                      className="form-control"
                      {...register("agency.pincode")}
                      onChange={(e) => {
                        setValue("agency.pincode", e.target.value);
                        if (
                          e.target.value.length == 6 &&
                          lowerCase(watch("agency.countryCode") || "") === "in"
                        )
                          getCityAndState(parseInt(e.target.value));
                      }}
                    />
                    <LoadingSpinnerWithOverlay loading={pincodeDataLoader} />
                  </div>
                  {errors.agency?.pincode && (
                    <p className="text-danger small">
                      {errors.agency?.pincode?.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="row">
                <div
                  className={
                    lowerCase(watch("agency.countryCode")) === "in"
                      ? "mb-3 col-4"
                      : "mb-3 col-6"
                  }
                >
                  <label
                    className={
                      lowerCase(watch("agency.countryCode")) === "in"
                        ? "required"
                        : ""
                    }
                  >
                    City
                  </label>
                  <input
                    className="form-control"
                    {...register("agency.city")}
                  />
                  {errors.agency?.city && (
                    <p className="text-danger small">
                      {errors.agency?.city?.message}
                    </p>
                  )}
                </div>

                <div
                  className={
                    lowerCase(watch("agency.countryCode")) === "in"
                      ? "mb-3 col-4"
                      : "mb-3 col-6"
                  }
                >
                  <label
                    className={
                      lowerCase(watch("agency.countryCode")) === "in"
                        ? "required"
                        : ""
                    }
                  >
                    State
                  </label>
                  <input
                    className="form-control"
                    {...register("agency.state")}
                  />
                  {errors.agency?.state && (
                    <p className="text-danger small">
                      {errors.agency?.state?.message}
                    </p>
                  )}
                </div>
                {lowerCase(watch("agency.countryCode")) === "in" && (
                  <div
                    className={
                      lowerCase(watch("agency.countryCode")) === "in"
                        ? "mb-3 col-4"
                        : "mb-3 col-6"
                    }
                  >
                    <label
                      className={
                        lowerCase(watch("agency.countryCode")) === "in"
                          ? "required"
                          : ""
                      }
                    >
                      District
                    </label>
                    <select
                      className="form-control"
                      {...register("agency.district")}
                    >
                      <option value="">Select District</option>
                      {districts?.map((district, index) => (
                        <option key={index} value={district}>
                          {district}
                        </option>
                      ))}
                    </select>{" "}
                    {errors.agency?.district && (
                      <p className="text-danger small">
                        {errors.agency?.district?.message}
                      </p>
                    )}
                  </div>
                )}
              </div>

              <div className="mb-3">
                <label className="required">Address</label>
                <textarea
                  className="form-control"
                  {...register("agency.addressLine")}
                />
                {errors.agency?.addressLine && (
                  <p className="text-danger small">
                    {errors.agency?.addressLine?.message}
                  </p>
                )}
              </div>

              <div className="mb-3">
                <AppCkEditor
                  label="Quotation Footer"
                  className="mt-2"
                  value={watch("agency.quotationFooter")}
                  onChange={(value) => {
                    setValue("agency.quotationFooter", value || "");
                    if (value?.trim()) clearErrors("agency.quotationFooter");
                  }}
                  errorMessage={errors.agency?.quotationFooter?.message}
                />
              </div>

              <div className="mb-3 col-6">
                <label>Fake Markup Percentage</label>
                <input
                  className="form-control"
                  {...register("agency.fakeMarkUpPercentage", {
                    valueAsNumber: true,
                  })}
                  onInput={restrictToNumbers}
                />
                {errors.agency?.fakeMarkUpPercentage && (
                  <p className="text-danger small">
                    {errors.agency?.fakeMarkUpPercentage?.message}
                  </p>
                )}
              </div>
            </>
          )}

          <div className="text-end my-4">
            <LoadingButton
              btnType="submit"
              btnClass="btn btn-primary"
              loading={loading}
            >
              Update Profile
            </LoadingButton>
          </div>
        </form>
      </div>
      <OtpModal
        show={otpModal}
        handleClose={() => setOtpModal(!otpModal)}
        setIsEmailVerified={() => setIsEmailVerified(true)}
        setIsPhoneNumberVerified={() => setIsPhoneNumberVerified(true)}
        userId={userData?.id}
        isEmailVerification={isEmailVerification}
      />
    </>
  );
};

export default MyProfile;
