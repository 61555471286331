import React, { Dispatch, SetStateAction, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { IJSONResponse } from "../../activitiescommonfnb/types/common.interface";
import { activityAxios } from "../../axios/activityAxios";
import { toast } from "react-toastify";

const OtpModal: React.FC<{
  show: boolean;
  handleClose: () => void;
  userId: number | undefined;
  setIsEmailVerified: Dispatch<SetStateAction<boolean>>;
  setIsPhoneNumberVerified: Dispatch<SetStateAction<boolean>>;
  isEmailVerification: boolean;
}> = ({
  show,
  handleClose,
  userId,
  setIsEmailVerified,
  isEmailVerification,
}) => {
  const [otp, setOtp] = React.useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    let apiRes;
    if (isEmailVerification) {
      apiRes = await activityAxios.post<IJSONResponse>(
        "/auth/validateUserEmailVerificationOtp",
        {
          // userId: userId,
          otp,
        }
      );
    } else {
      apiRes = await activityAxios.post<IJSONResponse>(
        "/auth/validateUserPhoneVerificationOtp",
        {
          // userId: userId,
          otp,
        }
      );
    }
    if (apiRes?.data.success) {
      toast.success("Verified successfully");
      setIsEmailVerified(true);
      handleClose();
    } else {
      toast.error(apiRes?.data.errorMessage || "Invalid OTP");
    }
    setLoading(false);
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Verify OTP</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Enter OTP</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter the OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
            />
          </Form.Group>
          <div className="text-end">
            <Button
              variant="primary"
              type="submit"
              disabled={loading || otp.trim() === ""}
            >
              {loading ? "Verifying..." : "Verify"}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default OtpModal;
