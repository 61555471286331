import { activityAxios } from "../../../axios/activityAxios";
import { IJSONResponse } from "../../../activitiescommonfnb/types/common.interface";
import { AppAsyncSelect } from "../../../commonUi/AppSelect";
import { ICountryData } from "../../../activitiescommonfnb/types/User.interface";

export const CountrySelect: React.FC<{
  containerClassName?: string;
  labelClassName?: string;
  label?: string;
  value?: ICountryData | null;
  onChange?: (country: ICountryData | null) => void;
}> = (props) => {
  const searchCountry = async (searchQuery: string) => {
    const apiRes = await activityAxios.post<IJSONResponse<ICountryData[]>>(
      "/search/getMasterCountries",
      { searchQuery }
    );
    return apiRes.data.result || [];
  };

  return (
    <div className={props.containerClassName}>
      {props.label && (
        <label htmlFor="country" className={props.labelClassName}>
          {props.label}
        </label>
      )}
      <AppAsyncSelect
        placeholder="search country..."
        loadOptions={searchCountry}
        isMulti={false}
        value={props.value}
        isClearable
        onChange={props.onChange}
        getOptionLabel={(country) => country.name}
      />
    </div>
  );
};
