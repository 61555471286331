import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ErrorMessage } from "../../commonUi/ErrorMessage";
import { ISignUp } from "../../activitiescommonfnb/types/signUp.interface";
import { TAgencySchema } from "../../activitiescommonfnb/schemas/signUp.schema";
import { Path } from "react-hook-form";
import { Spinner } from "react-bootstrap";
import { CountrySelect } from "../../components/common/Selects/CountrySelect";
import { ICountryData } from "../../activitiescommonfnb/types/User.interface";
import { activityAxios } from "../../axios/activityAxios";
import { IJSONResponse } from "../../activitiescommonfnb/types/common.interface";
import { toast } from "react-toastify";
import { capitalize, lowerCase } from "lodash";
import { handleWheel } from "../../helper/globalHelper";

const AgencyDetailsForm: React.FC<{
  handleGoBack: () => void;
  signUpDetails: ISignUp;
  agencyDetailError: { [key in Path<TAgencySchema>]: string } | null;
  setSignUpDetails: Dispatch<SetStateAction<ISignUp>>;
  loadingButton: boolean;
  saveAgencyDetail: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}> = (props) => {
  const [countryData, setCountryData] = useState<ICountryData | null>(null);
  const [districts, setDistricts] = useState<string[] | null>(null);
  const getCityAndState = async (pincode: number) => {
    const apiRes = await activityAxios.post<IJSONResponse<any>>(
      "/search/getPincodeData",
      { pincode }
    );
    if (apiRes?.data.success) {
      props.setSignUpDetails((old) => ({
        ...old,
        state: capitalize(apiRes?.data.result?.state),
        city: "",
        district: "",
      }));
      setDistricts(apiRes?.data.result?.district);
    } else {
      toast.error(apiRes?.data.errorMessage || "Something Went Wrong");
    }
  };

  useEffect(() => {
    if (!props.signUpDetails.pincode) {
      props.setSignUpDetails((old) => ({
        ...old,
        state: "",
        city: "",
        district: "",
      }));
    }
  }, [props.signUpDetails.pincode]);

  return (
    <>
      <div className="text-end position-relative">
        <button
          style={{ bottom: "-4px", right: "10px" }}
          className="btn small btn-small position-absolute"
          onClick={props.handleGoBack}
        >
          <i className="fa-solid fa-arrow-left me-2"></i>
          <b> Go Back</b>
        </button>
      </div>
      <form>
        <div>
          <label htmlFor="" className="mt-4 small required">
            Agency Name
          </label>
          <input
            className="form-control"
            type="text"
            value={props.signUpDetails?.agencyName}
            onChange={(e) => {
              props.setSignUpDetails((old) => {
                return { ...old, agencyName: e.target.value };
              });
            }}
          />
          <ErrorMessage errorMessage={props.agencyDetailError?.agencyName} />
        </div>

        <div>
          <label htmlFor="" className="mt-3 small required">
            Address
          </label>
          <textarea
            className="form-control"
            value={props.signUpDetails?.addressLine}
            onChange={(e) => {
              props.setSignUpDetails((old) => {
                return { ...old, addressLine: e.target.value };
              });
            }}
          />
          <ErrorMessage errorMessage={props.agencyDetailError?.addressLine} />
        </div>
        <div className="row">
          <div className="col-6">
            <label htmlFor="" className="mt-3 small required">
              Country
            </label>
            <CountrySelect
              value={countryData}
              onChange={(country) => {
                setCountryData(country);
                props.setSignUpDetails((old) => {
                  return { ...old, countryCode: country?.code || null };
                });
                if (country?.code !== "IN") {
                  props.setSignUpDetails((old) => {
                    return {
                      ...old,
                      district: "",
                      city: "",
                      state: "",
                      pincode: null,
                    };
                  });
                }
              }}
            />
            <ErrorMessage errorMessage={props.agencyDetailError?.countryCode} />
          </div>

          <div className="col-6">
            <label htmlFor="" className="mt-3 small required">
              Pincode
            </label>
            <input
              onWheel={handleWheel}
              className="form-control"
              type="number"
              value={props.signUpDetails?.pincode?.toString() || ""}
              onChange={(e) => {
                props.setSignUpDetails((old) => {
                  return { ...old, pincode: e.target.value };
                });
                if (
                  e.target.value.length == 6 &&
                  lowerCase(props.signUpDetails.countryCode || "") === "in"
                ) {
                  getCityAndState(parseInt(e.target.value));
                }
              }}
            />
            <ErrorMessage errorMessage={props.agencyDetailError?.pincode} />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <label htmlFor="" className="mt-3 small ">
              State :
            </label>
            <input
              className="form-control"
              type="text"
              value={props.signUpDetails?.state}
              onChange={(e) => {
                props.setSignUpDetails((old) => {
                  return { ...old, state: e.target.value };
                });
              }}
            />{" "}
            <ErrorMessage errorMessage={props.agencyDetailError?.state} />
          </div>
          {lowerCase(props.signUpDetails.countryCode || "") == "in" && (
            <div className="col-6">
              <label htmlFor="" className="mt-3 small">
                District :
              </label>
              <select
                className="form-control"
                value={props.signUpDetails?.district || ""}
                onChange={(e) => {
                  props.setSignUpDetails((old) => ({
                    ...old,
                    district: e.target.value,
                  }));
                }}
              >
                <option value="" disabled>
                  Select
                </option>
                {districts?.map((district, index) => (
                  <option key={index} value={district}>
                    {capitalize(district)}
                  </option>
                ))}
              </select>

              <ErrorMessage errorMessage={props.agencyDetailError?.district} />
            </div>
          )}
          <div
            className={
              lowerCase(props.signUpDetails.countryCode || "") !== "in"
                ? "col-6"
                : ""
            }
          >
            <label htmlFor="" className="mt-3 small">
              City :
            </label>
            <input
              className="form-control"
              type="text"
              value={props.signUpDetails?.city}
              onChange={(e) => {
                props.setSignUpDetails((old) => {
                  return {
                    ...old,
                    city: e.target.value,
                  };
                });
              }}
            />
            <ErrorMessage errorMessage={props.agencyDetailError?.city} />
          </div>
        </div>

        <div className="text-end">
          <button
            type="submit"
            className="btn-sm btn btn-primary w-100 mt-4"
            onClick={(e) => props.saveAgencyDetail(e)}
          >
            {props.loadingButton ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Sign Up"
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default AgencyDetailsForm;
