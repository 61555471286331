import React, { Dispatch, SetStateAction } from "react";
import { ISignUp } from "../../activitiescommonfnb/types/signUp.interface";
import { ErrorMessage } from "../../commonUi/ErrorMessage";
import { Spinner } from "react-bootstrap";
import { IntlTelInputWrapper } from "../../commonUi/IntlTelInputWrapper";
import { Path } from "react-hook-form";
import { TSignUp } from "../../activitiescommonfnb/schemas/signUp.schema";

const UserForm: React.FC<{
  signUpDetails: ISignUp;
  setSignUpDetails: Dispatch<SetStateAction<ISignUp>>;
  signUpError: { [key in Path<TSignUp>]: string } | null;
  loadingButton: boolean;
  registerUser: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}> = (props) => {
  return (
    <>
      <div>
        <label htmlFor="" className="mt-4 small">
          First Name:
        </label>
        <input
          className="form-control"
          type="text"
          value={props.signUpDetails?.firstName}
          onChange={(e) =>
            props.setSignUpDetails((old) => ({
              ...old,
              firstName: e.target.value,
            }))
          }
        />
        <ErrorMessage errorMessage={props.signUpError?.firstName || ""} />
      </div>

      <div>
        <label htmlFor="" className="mt-3 small">
          Last Name:
        </label>
        <input
          className="form-control"
          type="text"
          value={props.signUpDetails?.lastName}
          onChange={(e) =>
            props.setSignUpDetails((old) => ({
              ...old,
              lastName: e.target.value,
            }))
          }
        />
        <ErrorMessage errorMessage={props.signUpError?.lastName || ""} />
      </div>

      <div className="my-3">
        <IntlTelInputWrapper
          defaultValue={props.signUpDetails?.phoneNumber}
          label="Phone Number"
          onChange={(phoneNumber, phoneCode, isValid) =>
            props.setSignUpDetails((old) => ({
              ...old,
              phoneNumber,
              phoneCode,
              isPhoneValid: isValid,
            }))
          }
        />
        <ErrorMessage errorMessage={props.signUpError?.phoneNumber || ""} />
      </div>

      <div className="text-end">
        <button
          type="submit"
          className="btn-sm btn btn-primary w-100 mt-4"
          onClick={(e) => props.registerUser(e)}
          disabled={props.loadingButton}
        >
          {props.loadingButton ? (
            <Spinner animation="border" size="sm" />
          ) : (
            "Send OTP"
          )}
        </button>
      </div>
    </>
  );
};

export default UserForm;
