import { toast } from "react-toastify";
import { activityAxios } from "../../axios/activityAxios";
import { useEffect, useState } from "react";
import moment from "moment";
import { IUserCallLogs } from "../../activitiescommonfnb/types/User.interface";
import {
  AppRangeDatePicker,
  IDateRange,
} from "../../commonUi/AppRangeDatePicker";
import ReactDatePicker from "react-datepicker";

export const StaffCallLogs = () => {
  const [dateFilter, setDateFilter] = useState<IDateRange>({
    from: moment().startOf("day").toDate(),
    to: moment().endOf("day").toDate(),
  });
  const [callLogs, setCallLogs] = useState<Array<IUserCallLogs>>([]);
  const [loading, setLoading] = useState(false);
  const [refreshTime, setRefreshTime] = useState(20);
  const getStaffCallLogs = async () => {
    if (loading) {
      return;
    }
    setRefreshTime(20);
    setLoading(true);
    try {
      const apiRes = await activityAxios.post("/user/getStaffCallLogs", {
        dateFilter: {
          from: moment(dateFilter.from).startOf("day")?.toString(),
          to: moment(dateFilter.to).endOf("day")?.toString(),
        },
      });
      if (apiRes.data.success) {
        setCallLogs(apiRes.data.result);
      } else {
        throw new Error(apiRes.data.error);
      }
    } catch (err: any) {
      toast.error(err.message || "Something went wrong");
    }
    setLoading(false);
  };

  let refreshIntervalId: null | NodeJS.Timer = null;

  useEffect(() => {
    refreshIntervalId = setInterval(() => {
      setRefreshTime((old) => {
        if (old > 0) return old - 1;
        else {
          getStaffCallLogs();
          return 20;
        }
      });
    }, 1000);
    return () => {
      if (refreshIntervalId) clearInterval(refreshIntervalId);
    };
  }, []);

  useEffect(() => {
    if (dateFilter.from && dateFilter.to) {
      getStaffCallLogs();
    }
  }, [dateFilter]);

  return (
    <div className="">
      <div className="">
        <AppRangeDatePicker
          showIcon
          icon="fa fa-calendar"
          label="Date Range"
          dateRange={dateFilter}
          onChange={(dateRange) => {
            setDateFilter(dateRange);
          }}
          disabled={true}
          className="small"
          containerClassName="small d-flex align-items-center justify-content-between"
          // wrapperClassName="w-100"
        />
        <div className="d-flex align-items-center justify-content-between mt-3 mb-2">
          <b className="">Total ({callLogs.length || 0})</b>
          <div role="button" className="small" onClick={getStaffCallLogs}>
            Auto refresh in {refreshTime}
            <i
              className={`fa-solid fa-arrows-rotate ms-1 ${
                loading && `rotateLoading`
              }`}
            ></i>
          </div>
        </div>
      </div>

      <div className="">
        {/* {loading && (
          <span className="small text-center bg-light rounded">loading...</span>
        )} */}
        {!!callLogs.length ? (
          callLogs.map((callLog, index) => {
            return (
              <div className="py-2 border-bottom small" key={callLog.id}>
                <div className="row align-items-center" key={callLog.id}>
                  <div className="col-9">
                    {callLog.name || "Unknown"}{" "}
                    <span
                      className={`${
                        callLog.type === "MISSED"
                          ? `text-danger`
                          : callLog.type === "INCOMING"
                          ? `text-success`
                          : callLog.type === "OUTGOING"
                          ? `text-muted`
                          : ""
                      } small fw-bold`}
                    >
                      {" "}
                      {callLog.type === "MISSED" ? callLog.type : ""}
                      {/* ({callLog.type}) */}
                      <i
                        className={`fa-solid ms-1 ${
                          callLog.type === "MISSED"
                            ? ``
                            : callLog.type === "INCOMING"
                            ? `fa-arrow-down`
                            : callLog.type === "OUTGOING"
                            ? `fa-arrow-up`
                            : ""
                        } `}
                      ></i>
                    </span>
                    <br />
                    <span className="small">
                      <b>{callLog.phoneNumber}</b>{" "}
                      {moment(callLog.dateTime).format("  DD-MMM-YYYY ")}
                    </span>
                  </div>

                  <div className="col-3 text-end small">
                    {callLog.duration} sec
                    <br />
                    {moment(callLog.dateTime).format("hh:mm A ")}
                  </div>
                </div>
              </div>
            );
          })
        ) : loading ? (
          <p className="text-center">
            <div className="spinner-border m-5" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </p>
        ) : (
          <p className="text-center border py-5 rounded h-100 small text-danger">
            <i className="fa-solid fa-kiwi-bird fs-2 mb-3 text-muted"></i>
            <br />
            No call logs found !
          </p>
        )}
      </div>
    </div>
  );
};
