import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";

import { getAuthToken, getSupplierAuthToken } from "../../functions/commonFx";
import { useAppSelector } from "../../store";
import { EnumUserType } from "../../activitiescommonfnb/types/User.interface";

export const ProtectedRoute: React.FC<{
  children: ReactNode;
  onlyStaffRoute?: boolean;
  staffAndB2BUserRooute?: boolean;
}> = (props) => {
  const authToken = getAuthToken();
  const supplierAuthToken = getSupplierAuthToken();

  const { data: userData } = useAppSelector((state) => state.authData);

  if (userData?.userType === EnumUserType.TRAVEL_AGENT) {
    if (!userData.agencyId && !window.location.pathname.includes("myProfile")) {
      return <Navigate to="/myProfile" replace />;
    }
  }

  if (!(authToken || supplierAuthToken) || !userData) {
    return <Navigate to="/login" />;
  }

  if (
    props.staffAndB2BUserRooute &&
    userData?.userType !== EnumUserType.STAFF &&
    userData?.userType !== EnumUserType.TRAVEL_AGENT
  ) {
    return <Navigate to="/notFound" replace />;
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};
