import React, { Dispatch, SetStateAction } from "react";
import { ISignUp } from "../../activitiescommonfnb/types/signUp.interface";
import { ErrorMessage } from "../../commonUi/ErrorMessage";
import { Spinner } from "react-bootstrap";

const VerifyEmailForm: React.FC<{
  handleGoBack: () => void;
  signUpDetails: ISignUp;
  setSignUpDetails: Dispatch<SetStateAction<ISignUp>>;
  loadingButton: boolean;
  emailError: boolean;
  verifyEmail: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}> = (props) => {
  return (
    <>
      <div className="text-end position-relative">
        <button
          style={{ bottom: "-4px", right: "10px" }}
          className="btn small btn-small position-absolute"
          onClick={props.handleGoBack}
        >
          {" "}
          <i className="fa-solid fa-arrow-left me-2"></i>
          <b> Go Back</b>
        </button>
      </div>
      <form>
        <div>
          <label htmlFor="" className="mt-4 small">
            Email :
          </label>
          <input
            className="form-control"
            type="text"
            value={props.signUpDetails?.email}
            onChange={(e) => {
              props.setSignUpDetails((old) => {
                return { ...old, email: e.target.value };
              });
            }}
          />
          <ErrorMessage
            errorMessage={props.emailError ? "Please Enter Email" : ""}
          />
        </div>

        <div className="text-end">
          <button
            type="submit"
            className="btn-sm btn btn-primary w-100 mt-4"
            onClick={(e) => props.verifyEmail(e)}
            disabled={props.loadingButton}
          >
            {props.loadingButton ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "verify Email"
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default VerifyEmailForm;
