import React, { ReactNode, useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export const ReCaptchaWrapper: React.FC<{
  children: ReactNode;
}> = (props) => {
  const CAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  if (!CAPTCHA_SITE_KEY) {
    throw new Error("CAPTCHA site key is missing!");
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA_SITE_KEY}>
      {props.children}
    </GoogleReCaptchaProvider>
  );
};
