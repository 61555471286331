import { z } from "zod";
import { agencySchema } from "./signUp.schema";

export const ProfileDataSchema = z.object({
  firstName: z.string().min(1, "First name is required."),
  lastName: z.string().min(1, "Last name is required."),
  email: z
    .string()
    .email("Please enter a valid email address.")
    .min(1, "Email is required."),
  phoneNumber: z.string().min(1, "Phone Code is invalid."),
  phoneCode: z.string().min(1, "Phone number is required."),
  agency: agencySchema.nullable(),
});

export type TProfileData = z.infer<typeof ProfileDataSchema>;
