import React, { ReactNode } from "react";
import { useAppSelector } from "../../store";
import { getAuthToken } from "../../functions/commonFx";
import { Navigate } from "react-router-dom";
import { EnumUserType } from "../../activitiescommonfnb/types/User.interface";

export const GuestRoutes: React.FC<{
  children: ReactNode;
}> = (props) => {
  const token = getAuthToken();
  const { data: userData } = useAppSelector((state) => state.authData);

  if (userData?.userType === EnumUserType.TRAVEL_AGENT) {
    if (!userData.agencyId) {
      return <Navigate to="/myProfile" replace />;
    }
  }

  if (token && userData) {
    return <Navigate to="/" replace />;
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};
