import { z } from "zod";

export const signUpSchema = z.object({
  userId: z.number().nullish(),
  firstName: z.string().min(1),
  lastName: z.string().optional(),
  phoneNumber: z.string().min(1),
  phoneCode: z.string().min(1),
  isPhoneValid: z.boolean(),
});

export type TSignUp = z.infer<typeof signUpSchema>;

export const agencySchema = z.object({
  agencyName: z.string().min(3),
  state: z.string().nullish(),
  countryCode: z.string().min(1),
  city: z.string().nullish(),
  district: z.string().nullish(),
  addressLine: z.string().min(1),
  pincode: z.string(),
  quotationFooter: z.string().nullish(),
  fakeMarkUpPercentage: z.number().nullish(),
});

export type TAgencySchema = z.infer<typeof agencySchema>;
