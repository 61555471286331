import { useAppSelector } from "../../store";
import facebookIcon from "../../assets/images/socialIcons/facebook.png";
import instagramIcon from "../../assets/images/socialIcons/instagram.png";
import watsappIcon from "../../assets/images/socialIcons/whatsapp.png";
import footerStyle from "./FooterStyle.module.scss";
import logo from "../../assets/images/packageKartLogo.png";
import { Link } from "react-router-dom";
import { CallLogsModal } from "../../screens/CallLogs/CallLogsModal";
import { useState } from "react";
import { Card, Collapse } from "react-bootstrap";
import { EnumUserType } from "../../activitiescommonfnb/types/User.interface";

const Footer = () => {
  const [modalShow, setModalShow] = useState(false);
  const { data: userData } = useAppSelector((state) => state.authData);
  const [openCallLogsPopOver, setOpenCallLogsPopOver] = useState(false);

  return (
    <>
      {/* <div
        dangerouslySetInnerHTML={{
          __html: siteData?.ssrHtml?.footer,
        }}
      ></div> */}

      <footer className={footerStyle.footer}>
        <div className={footerStyle.firstRow}>
          <div className="container"></div>
        </div>
        <div className={footerStyle.footerContentContainer}>
          <div className="container">
            <div className={footerStyle.secondRow}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="fw-bold mb-3">ABOUT US</div>
                  <p className="small pe-4">
                    At PackageKart.com, we harness over 20 years of expertise to
                    deliver comprehensive tour packages, including essential
                    components like attraction tickets and seamless transfer
                    services, exclusively for B2B travel agents.{" "}
                    <a href="/aboutus"> Know More...</a>
                  </p>
                </div>
                <div className="col-lg-3">
                  <ul className="small">
                    <li className="fw-bold fs-6"> QUICK LINKS </li>
                    <li>
                      <Link to={"/aboutus"} target="_blank">
                        About Us{" "}
                      </Link>
                    </li>

                    <li>
                      <a href="/terms_and_conditions" target="blank">
                        Terms & Conditions
                      </a>
                    </li>
                    <li>
                      <Link to={"/payment&refundpolicy"} target="_blank">
                        Payment & Refund Policy
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3">
                  <ul className="small">
                    <li className="fw-bold fs-6">CONTACT US </li>
                    <li>
                      <a href="mailto:info@packagekart.com">
                        info@packagekart.com{" "}
                      </a>
                    </li>
                    <li>
                      <a href="tel:+91 9996155563">+91 99961 55563</a>
                    </li>
                    <li> 290/1, SK Road, Near Bus Stand, Ladwa 136132</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={footerStyle.thirdRow}>
          <div className="container">
            <div className={footerStyle.socialIcons}>
              <div className="border-bottom border-secondary me-3  w-25"></div>
              <span>
                <img
                  className={footerStyle.socialIcon}
                  src={facebookIcon}
                  alt=""
                />
              </span>
              <span>
                <img
                  className={footerStyle.socialIcon}
                  src={watsappIcon}
                  alt=""
                />
              </span>
              <span>
                <img
                  className={footerStyle.socialIcon}
                  src={instagramIcon}
                  alt=""
                />
              </span>
              <div className="border-bottom border-secondary ms-3  w-25"></div>
            </div>
            <div className={footerStyle.copyRight}>
              A PRODUCT OF{" "}
              <Link
                to="https://sippisoftware.com/"
                target="_blank"
                className="text-white link-warning fw-bold"
              >
                SIPPI SOFTWARE SOLUTIONS PRIVATE LIMITED{" "}
              </Link>{" "}
              © {new Date().getFullYear()}
            </div>
          </div>
        </div>
      </footer>
      {userData?.userType === EnumUserType.STAFF && (
        <div id="callLogsFloatingButton">
          {openCallLogsPopOver && <CallLogsModal />}
          <button
            className={`btn bg-secondary rounded-pill  float-button `}
            // onClick={() => setModalShow(true)}
            onClick={() => setOpenCallLogsPopOver(!openCallLogsPopOver)}
          >
            <i
              className={`fa-solid ${
                openCallLogsPopOver ? "fa-xmark " : "fa-phone"
              } `}
            ></i>
          </button>
        </div>
      )}
    </>
  );
};
export default Footer;
