export enum EnumUserType {
  RETAIL = "retail",
  STAFF = "staff",
  TRAVEL_AGENT = "travelAgent",
}

export interface IAgency {
  id: number;
  agencyName: string;
  state?: string | null;
  countryCode: string;
  district?: string;
  city?: string | null;
  adminId?: number;
  pincode?: string | null;
  addressLine: string;
  quotationFooter: string;
  fakeMarkUpPercentage: number;
}

export interface IUserData {
  id: number;
  firstName: string;
  lastName: string;
  email: string | null;
  alias: string;
  userType: EnumUserType;
  phoneNumber: string;
  phoneCode: string;
  isAdmin: boolean;
  isPhoneVerified: boolean;
  isEmailVerified: boolean;
  agencyId: boolean;
  agency: IAgency;
  panNumber: string | null;
  createdAt: Date;
  updatedAt: Date;
  isSupervisor?: boolean;
}

export interface ICountryData {
  code: string;
  name: string;
}

export interface IUserCallLogs {
  id: number;
  type: string;
  rawType: number;
  name: string;
  duration: number;
  dateTime: string;
  timestamp: Date;
  phoneNumber: string;
  userId: number;
  user: IUserData | null; // Assuming UserEntity has an interface IUser
  createdAt: Date;
  updatedAt: Date;
}
