import React from "react";
import QuotationFooter from "./QuotationFooter";
import QuotationHeader from "./QuotationHeader";
import { IQuotationPreview } from "../../activitiescommonfnb/types/quotation.interface";
import Price from "../../commonUi/Price";
import QuotationTandC from "./QuotationTandC";
import _ from "lodash";
import moment from "moment";
import { THotelZod } from "../../activitiescommonfnb/schemas/hotel.schema";

const QuotationPreview = React.forwardRef<
  HTMLDivElement,
  {
    quotationPreview: IQuotationPreview | null;
    enquiryId: number | undefined;
    hotelData: THotelZod[] | undefined;
  }
>(({ quotationPreview, hotelData, enquiryId }, ref) => {
  const markUp: number = quotationPreview?.quotationData?.markupPercentage
    ? quotationPreview.quotationData.markupPercentage / 100
    : 0.05;

  const hotelCost = quotationPreview?.quotationData?.hotels?.reduce(
    (totalAcc, hotel) => {
      const hotelTotalCost = hotel.paxConfigurationJson?.reduce(
        (paxAcc, pax) => paxAcc + (pax.cost || 0),
        0
      );

      const hotelCostWithMarkup = Math.ceil(
        (hotelTotalCost || 0) * (1 + markUp)
      );
      return totalAcc + hotelCostWithMarkup;
    },
    0
  );

  const price = {
    flight: quotationPreview?.quotationData?.flightCost
      ? quotationPreview.quotationData.flightCost
      : 0,
    visa: quotationPreview?.quotationData?.visaCost
      ? quotationPreview.quotationData.visaCost
      : 0,
    landPart: quotationPreview?.quotationData?.landPartCost
      ? quotationPreview.quotationData.landPartCost * (1 + markUp)
      : 0,
    hotels: hotelCost || 0,
  };

  const getSinglehotelPrice = (index: number) => {
    const hotelCost = quotationPreview?.quotationData?.hotels[
      index
    ].paxConfigurationJson?.reduce((totalAcc, room) => {
      const hotelTotalCost = totalAcc + (room.cost || 0);
      return hotelTotalCost;
    }, 0);

    return Math.ceil((hotelCost || 0) * (1 + markUp));
  };

  const gtotal =
    (quotationPreview?.quotationData?.showBreakup
      ? price.flight + price.visa
      : 0) +
    price.landPart +
    price.hotels;

  return (
    <div
      id="pk-activity-quotation-preview"
      ref={ref}
      style={{ overscrollBehavior: "contain" }}
    >
      <QuotationHeader
        quotationPreview={quotationPreview}
        enquiryId={enquiryId}
      />
      <div className="p-4">
        {quotationPreview?.quotationData?.showBreakup ? (
          <table className="table table-bordered my-4">
            <thead>
              <tr>
                <th colSpan={3} className="bg-light">
                  Price :{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Flight Cost : </td>
                <td colSpan={2} className="p-2">
                  {price.flight ? (
                    <Price currency="₹" amount={price.flight} />
                  ) : (
                    "Not Included "
                  )}
                </td>
              </tr>
              <tr>
                <td>Visa Cost : </td>
                <td colSpan={2} className="p-2">
                  {price.visa ? (
                    <Price currency="₹" amount={price.visa} />
                  ) : (
                    "Not Included "
                  )}
                </td>
              </tr>
              <tr>
                <td>LandPart Cost :</td>
                <td colSpan={2} className="p-2">
                  {price.landPart ? (
                    <Price currency="₹" amount={price.landPart} />
                  ) : (
                    "Not Included "
                  )}
                </td>
              </tr>
              <tr>
                <td>Hotel :</td>
                <td colSpan={2} className="p-0">
                  {true ? (
                    <table className="table mb-0">
                      {quotationPreview?.quotationData?.hotels?.map(
                        (hotel, index) => (
                          <>
                            <tr key={index} className="bg-light">
                              <td width={"40%"}>
                                <span className="small bg-light text-muted p-0">
                                  Hotel Name :{" "}
                                </span>{" "}
                                <br />
                                {
                                  hotelData?.find(
                                    (item) => item.id === hotel.hotelId
                                  )?.name
                                }{" "}
                              </td>
                              <td width={"40%"}>
                                <span className="small bg-light text-muted p-0">
                                  Check In :{" "}
                                </span>{" "}
                                <br />
                                {moment(hotel.checkIn).format("l")}
                              </td>
                              <td width={"40%"}>
                                <span className="small bg-light text-muted p-0">
                                  Check Out :{" "}
                                </span>{" "}
                                <br />
                                {moment(hotel.checkOut).format("l")}
                              </td>
                              <td width={"40%"}>
                                <span className="small bg-light text-muted p-0">
                                  Rooms :{" "}
                                </span>{" "}
                                <br />
                                {hotel.roomCount}
                              </td>
                              <td width={"40%"}>
                                <span className="small bg-light text-muted p-0">
                                  Cost :{" "}
                                </span>{" "}
                                <br />
                                <Price
                                  className="bg-light p-0"
                                  amount={getSinglehotelPrice(index)}
                                  currency="₹"
                                />
                              </td>
                            </tr>
                            {hotel.paxConfigurationJson?.map((obj) => (
                              <tr className="border-bottom">
                                <td width={"40%"}>
                                  <span className="small  text-muted p-0">
                                    Adult Count :{" "}
                                  </span>{" "}
                                  <br />
                                  {obj.adultCount}
                                </td>
                                <td width={"40%"}>
                                  <span className="small  text-muted p-0">
                                    Child Count :{" "}
                                  </span>{" "}
                                  <br />
                                  {obj.childCount}
                                </td>
                                <td width={"40%"}>
                                  <span className="small  text-muted p-0">
                                    Meal Plan :{" "}
                                  </span>{" "}
                                  <br />
                                  {obj.mealPlan}
                                </td>
                                <td width={"40%"}>
                                  <span className="small  text-muted p-0">
                                    Category :{" "}
                                  </span>{" "}
                                  <br />
                                  <br />
                                  {
                                    hotelData
                                      ?.find(
                                        (item) => item.id === hotel.hotelId
                                      )
                                      ?.rooms?.find(
                                        (obj1) => obj1.id === obj.roomId
                                      )?.category
                                  }
                                </td>
                              </tr>
                            ))}
                          </>
                        )
                      )}
                    </table>
                  ) : (
                    "Not Included"
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="bg-light">
                  Grand Total:
                </td>
                <td>
                  <Price currency="₹" amount={gtotal} />
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          //  bug fix by harsh (temporary),
          quotationPreview?.quotationData?.hotels?.[0].hotelId && (
            <table className="table table-bordered my-4 w-100">
              <thead>
                <tr>
                  <th colSpan={3} className="bg-light">
                    Price :{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Including Hotels, tours and transfers :</td>
                  <td colSpan={2}>
                    {" "}
                    <Price currency="₹" amount={gtotal} />
                  </td>
                </tr>
                <tr>
                  <td>Hotel : </td>
                  <td colSpan={2} className="p-0">
                    <table className="table m-0 w-100">
                      {quotationPreview?.quotationData?.hotels?.map(
                        (hotel, index) => (
                          <tr className="border-bottom" key={index}>
                            <td width={"40%"}>
                              <span className="small">Hotel Name : </span>
                              {
                                hotelData?.find(
                                  (item) => item.id === hotel.hotelId
                                )?.name
                              }
                            </td>
                            <td width={"40%"}>
                              <span className="small">Check In : </span>
                              {moment(hotel.checkIn).format("ll")}
                            </td>
                            <td width={"40%"}>
                              <span className="small">Check Out : </span>
                              {moment(hotel.checkOut).format("ll")}
                            </td>
                          </tr>
                        )
                      )}
                    </table>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="bg-light">
                    Grand Total:
                  </td>
                  <td>
                    <Price currency="₹" amount={gtotal} />
                  </td>
                </tr>
              </tbody>
            </table>
          )
        )}

        {quotationPreview?.previewBody ? (
          <>
            <span className="fw-bold ">Tour Inclusions : </span>
            <div
              className="mb-4 mt-2"
              dangerouslySetInnerHTML={{ __html: quotationPreview.previewBody }}
            ></div>
          </>
        ) : null}

        {quotationPreview?.cartItems ? (
          <>
            <div
              className="my-0"
              dangerouslySetInnerHTML={{ __html: quotationPreview.cartItems }}
            ></div>
          </>
        ) : null}

        <QuotationTandC
          termsAndConditions={quotationPreview?.termsAndConditions}
        />
      </div>
      <div className="page-break">
        <QuotationFooter />
      </div>
    </div>
  );
});

export default QuotationPreview;
