import { useState } from "react";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import { useAppSelector } from "../../store";
import { AllCallLogs } from "./AllCallLogs";
import { StaffCallLogs } from "./SatffCallLogs";
import { EnumUserType } from "../../activitiescommonfnb/types/User.interface";

export const CallLogsModal = (props: any) => {
  const { data: userData } = useAppSelector((state) => state.authData);
  const [showAllCallLogs, setShowAllCallLogs] = useState(false);
  const [key, setKey] = useState("home");
  return (
    <div className="bg-white callsContent">
      <div className="d-flex align-items-center justify-content-between callContentHeader p-3">
        {/* <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(key)}
          className="mb-3"
        >
          {userData?.isAdmin ? (
            showAllCallLogs ? (
              <Tab eventKey="myCallLogs" title="My Call Logs">
                {<StaffCallLogs />}
              </Tab>
            ) : (
              <Tab eventKey="staffCallLogs" title="All Staff Call Logs">
                <AllCallLogs />
              </Tab>
            )
          ) : (
            <Tab eventKey="myCallLogs" title="My Call Logs">
              {userData?.userType === EnumUserType.STAFF && <StaffCallLogs />}
            </Tab>
          )}

          <Tab eventKey="myCallLogs" title="My Call Logs">
            {userData?.userType === EnumUserType.STAFF && <StaffCallLogs />}
          </Tab>
          <Tab eventKey="staffCallLogs" title="All Staff Call Logs">
            All Staff call Logs
          </Tab>
        </Tabs> */}
        Staff Call Logs
        {userData?.isAdmin && (
          <div className="form-check form-switch small">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={showAllCallLogs}
              onChange={() => setShowAllCallLogs(!showAllCallLogs)}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault"
            >
              All Staff Logs
            </label>
          </div>
        )}
      </div>

      <div className="p-3">
        {userData?.isAdmin ? (
          showAllCallLogs ? (
            <AllCallLogs />
          ) : (
            <StaffCallLogs />
          )
        ) : (
          userData?.userType === EnumUserType.STAFF && <StaffCallLogs />
        )}
      </div>
      {/* <div>
        <Button onClick={props.onHide}>Close</Button>
      </div> */}
    </div>
  );
};
