export interface ISignUp {
  userId: number | null;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  phoneCode: string;
  email: string;
  agencyName: string;
  state: string;
  countryCode: string | null;
  addressLine: string;
  pincode: string | null;
  city: string;
  district: string;
  isPhoneValid: boolean;
}

export const signUpTemplate = {
  userId: null,
  firstName: "",
  lastName: "",
  phoneCode: "",
  phoneNumber: "",
  email: "",
  agencyName: "",
  state: "",
  countryCode: null,
  addressLine: "",
  pincode: null,
  city: "",
  district: "",
  isPhoneValid: false,
};
